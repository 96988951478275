.tagline-main {
  display: flex;
  padding: 4rem 5%;
  background: linear-gradient(
    to top right,
    white,
    white 50%,
    var(--shade-color) 50%
  );
  margin: 4rem;
  justify-content: center;
  height: 80vh;
}

.tagline-img {
  width: 450px;
  object-fit: cover;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../../assets/images/tagline.png");
  height: 100%;
}

.tagline-img-text {
  position: absolute;
  bottom: 12px;
  left: 16px;
  font-size: 1rem;
  color: white;
  margin: 0;
}

.tagline-content {
  padding: 0 0 0 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  color: var(--first-color);
  width: 35%;
}

.tagline-content-heading {
  font-weight: bold;
}

.tagline-content-details {
  font-weight: normal;
  font-size: 1.4rem;
}

.tagline-know-more {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.tagline-know-more-label {
  margin: 0;
  font-weight: 500;
}

.tagline-content-arrow-img {
  height: 8px;
  object-fit: contain;
}

.img-container {
  position: relative;
}

.tagline-know-more :hover {
  cursor: pointer;
  color: var(--primary-color);
}

@media screen and (max-width: 500px) {
  .tagline-main {
    flex-direction: column;
    margin: 0;
    padding: 4rem 1.5rem 3rem;
    gap: 2rem;
    background: var(--shade-color);
    height: fit-content;
  }

  .tagline-img {
    width: 100%;
  }

  .tagline-content {
    padding: 0;
    width: 100%;
  }

  .tagline-content-details {
    font-size: 1.2rem;
  }
}
