.investment-top-div{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 48.61%, rgba(0, 0, 0, 0.4) 100%),url('../../assets/images/investment.jpg');
  height: 90vh;
  background-size: cover ;
  position: relative;
  background-position: center;
  color: white;
}

.strategy-label{
  position: absolute;
  left: 4rem;
  bottom: 8px;
  font-weight: 700;
}

.strategy-evaluation-main{
  padding: 5% 4rem;
  text-align: left;
  color: var(--primary-color);
}

.strategy-evaluation-title{
  font-weight: 700;
  font-size: 1.5rem;
}

.evaluation-details{
  font-size: 1.2rem;
}

.evaluation-points-main{
  margin: 0 4rem 4rem ;
  background: #E4F2FF;
  padding: 3rem 20% 3rem 4rem;
  border-radius: 16px;
}
.evaluation-point{
  display: flex;
  gap:1rem;
  font-size: 1.2rem;
  text-align: left;
}

.evaluation-point-details{
  margin: 0;
}





.investment__left {
  background-color: #0a152b;
  color: white;
  border-bottom: 6px solid #1597e5;
  width: 98%;
  position: fixed;
  text-align: left;
  top: 120px;
  padding: 6px 5px 5px 25px;
  margin-bottom: 10px;
  margin-left: 1%;
  margin-right: 1%;
}

.investment__outer {
  max-width: 100%;
  padding-right: 3.7%;
  padding-left: 3.7%;
  text-align: justify;
  margin: 75px auto 30px;
}

.investment__left > h1 {
  margin-left: 1%;
  font-weight: 600;
  font-size: 33px;
}

@media (max-width: 616px) {
  .investment__outer {
    margin: 110px auto 30px;
  }
}

@media (max-width: 364px) {
  .investment__outer {
    margin: 150px auto 30px;
  }
}

@media (max-width: 772px){
  .investment__para{
    text-align: left;
  }
}

.investment__para {
  margin: 0;
  font-weight: 600;
}

.investment__right > p {
  /* text-align: justify; */
  font-size: 16px;
}

.investment__head {
  color: #393a4e;
  margin-top: 1.8%;
  font-size: 33px;
  font-weight: 600;
}

.investment__list {
  background-color: #c3d6b8;
  text-align: left;
  padding: 22px 35px 10px 10px;
  font-size: 16px;
  font-weight: 600;
}

.investment__img > img {
  width: 100%;
  height: 100%;
}

@media (max-width: 992px) {
  .investment__img {
    margin-top: 3%;
  }
}
