@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap);
:root{
  --first-color: #0A152B;
  --primary-color: #00813A;
  --shade-color: #E5FFF1;
  --secondary-color:#13A856;;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  overflow-x: hidden;
}

.App {
  text-align: center;
  font-family: Nunito,"Montserrat", sans-serif;
}

.navbar-main{
    display: flex ;
    justify-content: space-between !important;
    color: white;
    align-items: center;
    padding: 1.5rem 5rem 8px;
    background: var(--first-color);
    z-index: 150;
    position: fixed;
    width: 100%;
}

.eram-logo-img{
    object-fit: contain;
    width: 200px;
}

.navbar-tabs{
    display: flex;
    flex-direction: row;
    grid-gap:2rem;
    gap:2rem;
}

.navbar-main :hover{
    cursor: pointer;
}

.vertical-divider{
    width: 1px;
    height: 100%;
    background: white;
}

.vertical-divider :hover{
    pointer-events: none !important;
}

#collasible-nav-dropdown{
    color:white;
    padding: 0;
}

@media screen and (max-width: 772px) {
    .mobile-navbar-main {
      padding: 0.5rem 1rem 0 1rem;
      display: flex ;
      justify-content: space-between;
      align-items: center;
      background: var(--first-color);
      z-index: 150;
      position: fixed;
      width: 100%;
    }

    .menuItem-div {
        display: flex;
        flex-direction: column;
      }
    
      .drawer-container-main {
        position: fixed;
        z-index: 150;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        /* overflow-y: auto; */
        background-color: #fff;
        /* background-color: rgba(0, 0, 0, 0.7); */
        -webkit-animation-name: drawerFadeIn;
        -webkit-animation-duration: 0.4s;
        animation-name: drawerFadeIn;
        animation-duration: 0.4s;
        color: #fff;
      }
    
      .drawer-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        /* background-color: #fefefe; */
        width: 100%;
        height: 100%;
        -webkit-animation-name: contentSlideIn;
        -webkit-animation-duration: 0.4s;
        animation-name: contentSlideIn;
        animation-duration: 0.4s;
      }
    
      .menuItem-div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-radius: 10px;
        background-color: var(--first-color);
        margin: 2.5rem 0;
        padding: 1rem 2rem 3rem 2rem;
      }
      .menuContent-item {
        width: 100%;
        color: white;
        padding: 15px 5px 10px 5px;
        border-bottom: 1px solid #76939f;
        text-decoration: none;
      }

      #collasible-nav-dropdown{
        text-align: center;
      }

    .services-dropdown{
        display: flex;
        justify-content: center;
        margin: 16px 38%;    
    }
}
.footer__main {
  background-color: #666;
  max-width: 100%;
  /* align-items: center;
  margin-left: auto;
  margin-right: auto; */
}

.footer__sub {
  color: white;
  padding: 2% 6% 1% 6%;
}

.footer__add {
  display: flex;
  margin: 1%;
}

.footer__icon {
  margin-right: 2%;
}

.footer__first {
  text-align: left;
  padding: 1%;
}

.footer__pol {
  color: #666;
  border-color: #ebebeb;
  background-color: #ebebeb;
  font-weight: bolder;
  border-radius: 5%;
}

.footer__pol:hover {
  color: #666;
  border-color: #ebebeb;
  background-color: #ebebeb;
}
.footer__pol:focus {
  color: #666;
  border-color: #ebebeb;
  background-color: #ebebeb;
}

.footer__copy {
  margin: 4%;
}

.footer__image {
  width: 250px;
}

.footer__first > img {
  margin-bottom: 10px;
}

.footer__second > p {
  font-weight: 500;
  font-size: 16px;
}

.footer__second {
  text-align: justify;
  padding: 1%;
}

@media (max-width: 772px) {
  .footer__second {
    text-align: left;
  }

  .footer__first{
    text-align: center;
  }

  .footer__add {
    margin-left: 2.5rem;
    text-align: left;
  }
}

.footer__second > h3 {
  font-weight: 600;
  font-size: 50px;
}

.firm-top-div{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 48.61%, rgba(0, 0, 0, 0.4) 100%),url(/static/media/strategy2.a59a4c5e.jpg);
  height: 90vh;
  background-size: cover no-repeat;
  position: relative;
  background-position: center;
  color: white;
}

#sector-cards-id{
  display: flex;
  flex-wrap: wrap !important;
  padding: 4rem ;
  grid-gap: 4rem;
  gap: 4rem;
}

#sector-card-main-id{
  min-width: 40vw;
}

#sector-card-img-id{
  width: 100%;
  height: 300px;
}


@media screen and (max-width:772px) {

  #sector-card-main-id{
    width: 100%;
  }

  #sector-cards-id{
    padding: 1.5rem;
  }

  #sector-card-img-id{
    width: 100%;
    height: 300px;
  }
  
}
.firm__left {
  background-color: #0a152b;
  color: white;
  border-bottom: 6px solid #1597e5;
  width: 98%;
  position: fixed;
  text-align: justify;
  top: 120px;
  padding: 6px 5px 5px 25px;
  margin-bottom: 10px;
  margin-left: 1%;
  margin-right: 1%;
  z-index: 1000;
}

.firm__para {
  /* text-align: justify; */
  font-size: 16px;
  font-weight: 600;
}

.firm__left > h1 {
  margin-left: 1%;
  font-weight: 600;
  font-size: 33px;
}

.firm__page {
  max-width: 100%;
  padding-right: 3.7%;
  padding-left: 3.7%;
  text-align: justify;
  margin: 75px auto 30px;
}

@media (max-width: 772px) {
  .firm__page {
    text-align: left;
  }
}

.firm__img {
  border-radius: 5%;
  height: 400px;
  object-fit: cover;
}

.firm__heading {
  text-align: left;
  font-size: 33px;
  font-weight: 600;
  margin-bottom: 0.5em;
}

.card {
  border: none !important;
  text-align: center;
}

.button_div {
  padding: 5% 0%;
}

.button {
  box-shadow: 0 5px 0 #0e0e0e;
  background-color: #2a2a2a !important;
  border-color: #2a2a2a !important;
  font-weight: 600 !important;
  border-radius: 2rem !important;
  width: -webkit-max-content;
  width: max-content;
  /* font-family: verdana; */
  font-size: 16px;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 25px;
  padding-right: 25px;
  display: inline-block;
  align-items: center;
}

/* .firm__about {
  margin-top: 2%;
} */

.history__img > img {
  width: 100%;
  height: 100%;
}

.investment-top-div{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 48.61%, rgba(0, 0, 0, 0.4) 100%),url(/static/media/investment.8cdd711c.jpg);
  height: 90vh;
  background-size: cover ;
  position: relative;
  background-position: center;
  color: white;
}

.strategy-label{
  position: absolute;
  left: 4rem;
  bottom: 8px;
  font-weight: 700;
}

.strategy-evaluation-main{
  padding: 5% 4rem;
  text-align: left;
  color: var(--primary-color);
}

.strategy-evaluation-title{
  font-weight: 700;
  font-size: 1.5rem;
}

.evaluation-details{
  font-size: 1.2rem;
}

.evaluation-points-main{
  margin: 0 4rem 4rem ;
  background: #E4F2FF;
  padding: 3rem 20% 3rem 4rem;
  border-radius: 16px;
}
.evaluation-point{
  display: flex;
  grid-gap:1rem;
  gap:1rem;
  font-size: 1.2rem;
  text-align: left;
}

.evaluation-point-details{
  margin: 0;
}





.investment__left {
  background-color: #0a152b;
  color: white;
  border-bottom: 6px solid #1597e5;
  width: 98%;
  position: fixed;
  text-align: left;
  top: 120px;
  padding: 6px 5px 5px 25px;
  margin-bottom: 10px;
  margin-left: 1%;
  margin-right: 1%;
}

.investment__outer {
  max-width: 100%;
  padding-right: 3.7%;
  padding-left: 3.7%;
  text-align: justify;
  margin: 75px auto 30px;
}

.investment__left > h1 {
  margin-left: 1%;
  font-weight: 600;
  font-size: 33px;
}

@media (max-width: 616px) {
  .investment__outer {
    margin: 110px auto 30px;
  }
}

@media (max-width: 364px) {
  .investment__outer {
    margin: 150px auto 30px;
  }
}

@media (max-width: 772px){
  .investment__para{
    text-align: left;
  }
}

.investment__para {
  margin: 0;
  font-weight: 600;
}

.investment__right > p {
  /* text-align: justify; */
  font-size: 16px;
}

.investment__head {
  color: #393a4e;
  margin-top: 1.8%;
  font-size: 33px;
  font-weight: 600;
}

.investment__list {
  background-color: #c3d6b8;
  text-align: left;
  padding: 22px 35px 10px 10px;
  font-size: 16px;
  font-weight: 600;
}

.investment__img > img {
  width: 100%;
  height: 100%;
}

@media (max-width: 992px) {
  .investment__img {
    margin-top: 3%;
  }
}

.principal-top-div{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 48.61%, rgba(0, 0, 0, 0.4) 100%),url(/static/media/principal.dd03bc51.jpg);
  height: 90vh;
  background-size: cover ;
  position: relative;
  background-position: center;
  color: white;
}

.strategy-label{
  position: absolute;
  left: 4rem;
  bottom: 8px;
  font-weight: 700;
}

.strategy-evaluation-main{
  padding: 5% 4rem;
  text-align: left;
  color: var(--primary-color);
}

.strategy-evaluation-title{
  font-weight: 700;
  font-size: 1.5rem;
}

.evaluation-details{
  font-size: 1.2rem;
}

.evaluation-points-main{
  margin: 0 4rem 4rem ;
  background: #E4F2FF;
  padding: 3rem 20% 3rem 4rem;
  border-radius: 16px;
}
.evaluation-point{
  display: flex;
  grid-gap:1rem;
  gap:1rem;
  font-size: 1.2rem;
  text-align: left;
}

.evaluation-point-details{
  margin: 0;
}


.principal__left {
  background-color: #0a152b;
  color: white;
  border-bottom: 6px solid #1597e5;
  width: 98%;
  position: fixed;
  text-align: left;
  top: 120px;
  padding: 6px 5px 5px 25px;
  margin-bottom: 10px;
  margin-left: 1%;
  margin-right: 1%;
}

.principal__outer {
  max-width: 100%;
  padding-right: 3.7%;
  padding-left: 3.7%;
  text-align: justify;
  margin: 75px auto 30px;
}

.principal__para {
  margin: 0;
  font-weight: 600;
}

.principal__left > h1 {
  margin-left: 1%;
  font-weight: 600;
  font-size: 33px;
}


@media (max-width: 772px) {
  .principal__para {
    text-align: left;
  }
}

.principal__right > p {
  font-size: 16px;
}

.principal__heading {
  color: #393a4e;
  margin-top: 1.8%;
  font-size: 33px;
  font-weight: 600;
}

.principal__list {
  background-color: #c3d6b8;
  text-align: left;
  padding: 22px 35px 10px 10px;
  font-size: 16px;
  font-weight: 600;
}

.principal__img > img {
  width: 100%;
  height: 100%;
  margin-bottom: -10%;
  object-fit: cover;
}

@media (max-width: 400px){
  .principal__outer{
    margin: 115px auto 30px;
  }
}

@media (max-width: 1512px) {
  .principal__img > img {
    height: 100%;
  }
}

@media (max-width: 992px) {
  .principal__img {
    margin-top: 3%;
    margin-bottom: 2%;
  }
}

.strategy-top-div{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 48.61%, rgba(0, 0, 0, 0.4) 100%),url(/static/media/strategy3.09ca8e3a.jpg);
  height: 90vh;
  background-size: cover no-repeat;
  position: relative;
  background-position: center;
  color: white;
}

.strategy-label{
  position: absolute;
  left: 4rem;
  bottom: 8px;
  font-weight: 700;
}

.strategy-evaluation-main{
  padding: 5% 4rem 1rem 4rem !important;
  text-align: left;
  color: var(--first-color);
}

.strategy-evaluation-title{
  font-weight: 700;
  font-size: 1.5rem;
}

.evaluation-details{
  font-size: 1.2rem;
  padding: 1rem 4rem;
  text-align: left;
}

#evaluation-details-id{
  padding: 0;
}

.evaluation-points-main{
  margin: 0 4rem 4rem ;
  background: var(--shade-color);
  padding: 3rem 20% 3rem 4rem;
  border-radius: 16px;
}
.evaluation-point{
  display: flex;
  grid-gap:1rem;
  gap:1rem;
  font-size: 1.2rem;
  text-align: left;
}

.evaluation-point-details{
  margin: 0;
}

@media screen and (max-width:772px){
  .strategy-evaluation-main{
    padding: 2rem 1.5rem !important;
  }

  .evaluation-points-main{
    margin: 0 !important;
    padding: 2rem 1.5rem !important;
  }

  .strategy-label{
    left:1.5rem !important;
    font-size: 1.5rem !important;
  }

  .strategy-evaluation-title{
    font-size: 1.2rem !important;
  }

  .evaluation-point{
    font-size: 1rem !important;
  }

  .evaluation-details{
    font-size: 1 !important;
    padding: 1rem 1.5rem;
  }
}































.str__left {
  background-color: #0a152b;
  color: white;
  border-bottom: 6px solid #1597e5;
  width: 98%;
  position: fixed;
  text-align: justify;
  top: 120px;
  padding: 6px 5px 5px 25px;
  margin-bottom: 10px;
  margin-left: 1%;
  margin-right: 1%;
}

.str__left > h1 {
  margin-left: 1%;
  font-weight: 600;
  font-size: 33px;
}

.str__page {
  max-width: 100%;
  padding-right: 3.7%;
  padding-left: 3.7%;
  text-align: justify;
  margin: 75px auto 30px;
}

.str__para {
  font-size: 16px;
  font-weight: 600;
}

.str__list {
  background-color: #c3d6b8;
  padding: 22px 35px 10px 10px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1%;
}

.str__img > img {
  width: 100%;
  height: 100%;
}

.mid__heading {
  color: #393a4e;
  /* margin-bottom: 3%;  */
  font-size: 33px;
  font-weight: 600;
  margin-top: 0.5%;
}

@media (max-width: 992px) {
  .str__img {
    margin-top: 1.5%;
  }

  .mid__heading {
    margin-top: 2%;
  }
}

.str__bott1 > p {
  font-size: 16px;
  font-weight: 600;
}

.str__list1 {
  background-color: #c3d6b8;
  padding: 22px 35px 10px 10px;
  font-size: 16px;
  font-weight: 600;
}

.str__bott2 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 772px) {
  .str__page {
    text-align: left;
  }

  .str__bott {
    text-align: left;
  }
}

@media (max-width: 992px) {
  .str__bott2 {
    margin-bottom: 1.5%;
  }
}

.str__bott2 > p {
  margin-top: 1%;
  text-align: justify;
  font-size: 16px;
  font-weight: 600;
}

.str__bott {
  margin-bottom: 3%;
}

.contact-top-div{
  background-image: url(/static/media/contact-bg.72cee501.png);
  background-size: contain;
  background-position: center;
  padding-bottom: 5rem;
}

.contact-form-div-main{
  padding: 4rem 12%;
  background-image: url(/static/media/contact-bg.72cee501.png);
  background-size: contain;
  background-position: center;
  padding-bottom: 5rem;
}

.contact-form-card{
  padding: 4rem;
  background: white;
  box-shadow: 2px 10px 28px rgba(75, 0, 129, 0.12);
  border-radius: 10px;
}

.contact-us-label{
  letter-spacing: 0.01em;
  color: #4A99D3;
}

.contact-us-subtitle{
  font-size: 18px;
  color: #0F001A;
}

.contact-us-content{
  display: flex;
  padding: 2rem;
  justify-content: space-between;
}

.contact-form-main{
  width: 40%;
  display: flex;
  flex-direction: column;
  grid-gap:1rem;
  gap:1rem;
  text-align: left;
}

.contact-form-div{
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}

.contact-us-address{
  display: flex;
  flex-direction: column;
  width: 50%;
}

@media screen and (max-width:772px) {
  .contact-form-card{
    padding: 1rem 1.5rem;
  }

  .contact-form-div-main{
    padding: 3rem 1.5rem;
  }

  .contact-us-content{
    flex-direction: column;
    padding: 2rem 0 ;
    grid-gap:2rem;
    gap:2rem;
  }

  .contact-form-main{
    width: 100%;
  }

  .contact-us-address{
    width: 100%;
  }




}











.contact__top {
  background-color: #0a152b;
  color: white;
  border-bottom: 6px solid #1597e5;
  width: 98%;
  position: fixed;
  text-align: justify;
  top: 120px;
  padding: 5px;
  padding-left: 25px;
  margin-bottom: 10px;
  margin-left: 1%;
  margin-right: 1%;
}

.contact__top > h1 {
  margin-left: 1%;
  font-weight: 600;
  font-size: 33px;
}

.contact__map {
  padding-left: 3.7%;
  padding-right: 3.7%;
  padding-top: 30px;
  margin-top: 2%;
}

@media (max-width: 1281px) {
  .contact__map {
    padding-top: 48px;
  }
}

@media (max-width: 771px) {
  .contact__map {
    padding-top: 53px;
  }
}

@media (max-width: 513px) {
  .contact__map {
    padding-top: 60px;
  }
}

.contact__bottom {
  max-width: 100%;
  margin-top: 20px;
  padding-left: 4.7%;
  padding-right: 3.7%;
  margin-bottom: 2%;
  /* margin-bottom: 20px; */
}

.contact__bottom1 {
  /* margin-left: 1%;
  margin-right: 1%; */
  border: 15px solid #3d56b2;
  padding-left: 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  color: #3d56b2;
  text-align: left;
  height: 360px;
}

.contact__bottom1 > hr {
  border-top: 3px solid black;
  width: 10%;
}

.contact__bottom1 > p {
  font-weight: bold;
  font-size: 16px;
}

.contact__bottom2 {
  text-align: left;
}

.contact__input {
  display: block;
  margin-top: 10px;
  margin-bottom: 15px;
  width: 100%;
  padding-right: 0;
  padding: 23px;
}

@media (max-width: 768px) {
  .contact__input {
    margin: 4% auto;
    width: 100%;
  }
}

.contact__button {
  color: #3d56b2;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 35;
  height: 13%;
  font-weight: 900;
}

/* video::-webkit-media-controls {
    display: none;
} */

.home-top-div {
  height: 90vh;
  background-size: cover;
  position: relative;
}

.home-navbar-div {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
  width: 100%;
}

.home-top-card {
  width: 35%;
  background: var(--primary-color);
  color: white;
  padding: 1rem 2rem;
  text-align: left;
  margin-top: -5rem;
  border-radius: 8px;
  position: relative;
  z-index: 10;
}

.home-top-know-more {
  white-space: pre;
  margin: 0;
}

.home-top-know-more-div {
  border: 1px solid white;
  padding: 4px 124px 4px 12px;
  width: 20%;
  border-radius: 20px;
}

.home-top-know-more-div :hover {
  cursor: pointer;
  color: var(--secondary-color);
}

@media screen and (max-width: 500px) {
  .home-top-card {
    width: 75%;
  }

  .home-top-card-title {
    font-size: 1rem;
  }
}

.home__border {
  border-bottom: 6px solid #1597e5;
  position: fixed;
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
  z-index: 1000;
}
.card_div {
  width: 100%;
  margin: auto;
  padding: 2%;
  padding-top: 0%;
  padding-left: 3.7%;
  padding-right: 3.7%;
}

.home__container {
  max-width: 100%;
}

/* @media (max-width: 800px) {
  video {
    margin-top: 2%;
  }
} */

@media (max-width: 500px) {
  /* video {
    margin-top: 3%;
  } */
}

.rounded_border {
  border-radius: 5%;
}

.card_div > h2 {
  text-align: left;
  font-size: 40px;
  padding: 1% 0%;
  font-weight: bold;
  color: #393a4e;
  font-weight: 700;
  margin-bottom: 1%;
}

.home__img {
  border-radius: 5%;
  height: 400px;
  object-fit: cover;
}

.button {
  box-shadow: 0 5px 0 #0e0e0e;
  background-color: #2a2a2a !important;
  border-color: #2a2a2a !important;
  border-radius: 2rem !important;
  width: -webkit-max-content;
  width: max-content;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600 !important;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 25px;
  padding-right: 25px;
  display: inline-block;
}

.button_div {
  padding: 8% 0%;
}
.card {
  border: none !important;
}
/* 
video::-webkit-media-controls-volume-slider {
    display: none;
}

video::-webkit-media-controls-mute-button {
    display: none;
}

video::-webkit-media-controls-timeline {
    display: none;
}

video::-webkit-media-controls-current-time-display {
    display: none;
} */

.sc_title_accent {
  padding: 15px;
  background: linear-gradient(
    135deg,
    #248926 0%,
    #68bf6f 20%,
    rgb(58 68 68) 82%,
    rgb(0 0 0) 100%
  ) !important;
}

.title_wrap_in {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.more {
  color: #0b793a !important;
  background-color: #f3f4f4 !important;
  font-size: 25px;
}

.tagline__btn {
  color: #0b793a !important;
  font-size: 20px;
  margin-top: 30px;
}

.tagline-heading {
  text-align: left;
  font-size: 40px;
  font-weight: 700;
  color: #393a4e;
  padding: 1% 0;
  margin-bottom: 1%;
}

.outer {
  /* margin: auto; */
  padding: 2%;
  padding-left: 3.7%;
  padding-right: 3.7%;
}

.des_wrap {
  font-family: Verdana, Geneva, sans-serif !important;
  font-style: normal;
  font-weight: 400 !important;
}

.des_wrap > p {
  text-align: justify;
  line-height: 2rem;
  font-size: 20px;
}

@media (max-width: 960px) {
  .des_wrap > p {
    text-align: left;
  }
}

.sc_title_accent .title_wrap .sc_align_right {
  display: inline-block;
  width: 50%;
  padding: 53px 0;
  vertical-align: middle;
}

.sc_title_accent .title_wrap_in.sc_align_right .title_wrap {
  margin-left: 20%;
  margin-right: -15px;
  padding-left: 4%;
  background-color: #f3f4f4;
}

@media (max-width: 960px) {
  .sc_title_accent .title_wrap_in.sc_align_right .title_wrap {
    margin-left: 5%;
  }
}

@media (max-width: 767px) {
  .sc_title_accent .title_wrap_in.sc_align_right .title_wrap {
    margin-left: 0;
  }
}

@media (max-width: 400px) {
  .sc_title_accent .title_wrap_in.sc_align_right .title_wrap {
    margin-right: -15px;
    padding-left: 2%;
    background-color: #f3f4f4;
  }

  .sc_title_accent .title_wrap_in {
    padding: 35px 2px 25px;
    overflow: hidden;
  }
}

.sc_title_accent .title_wrap,
.sc_title_accent .des_wrap {
  width: 100%;
  /* max-width: 80%; */
  box-sizing: border-box;
  padding: 0px 0;
}

.sc_title_accent .title_wrap_in {
  text-align: left;
  background-color: #f3f4f4;
  padding: 40px 0 48px;
}

@media (max-width: 1279px) {
  .sc_title_accent .title_wrap_in {
    padding: 30px 0 32px;
  }
}

@media (min-width: 1279px) {
  .sc_title_accent .title_wrap_in {
    padding-left: 5%;
  }
}

@media (max-width: 1279px) {
  .sc_title_accent .title_wrap_in.sc_align_right .des_wrap {
    padding: 30px 10px 25px 4%;
  }
}

@media (max-width: 767px) {
  .sc_title_accent .title_wrap_in {
    padding: 35px 30px 25px;
    overflow: hidden;
  }
}

@media (max-width: 767px) {
  .sc_title_accent .title_wrap_in.sc_align_right {
    display: block;
  }
}

.tagline-main {
  display: flex;
  padding: 4rem 5%;
  background: linear-gradient(
    to top right,
    white,
    white 50%,
    var(--shade-color) 50%
  );
  margin: 4rem;
  justify-content: center;
  height: 80vh;
}

.tagline-img {
  width: 450px;
  object-fit: cover;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url(/static/media/tagline.85ac80f9.png);
  height: 100%;
}

.tagline-img-text {
  position: absolute;
  bottom: 12px;
  left: 16px;
  font-size: 1rem;
  color: white;
  margin: 0;
}

.tagline-content {
  padding: 0 0 0 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  color: var(--first-color);
  width: 35%;
}

.tagline-content-heading {
  font-weight: bold;
}

.tagline-content-details {
  font-weight: normal;
  font-size: 1.4rem;
}

.tagline-know-more {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.tagline-know-more-label {
  margin: 0;
  font-weight: 500;
}

.tagline-content-arrow-img {
  height: 8px;
  object-fit: contain;
}

.img-container {
  position: relative;
}

.tagline-know-more :hover {
  cursor: pointer;
  color: var(--primary-color);
}

@media screen and (max-width: 500px) {
  .tagline-main {
    flex-direction: column;
    margin: 0;
    padding: 4rem 1.5rem 3rem;
    grid-gap: 2rem;
    gap: 2rem;
    background: var(--shade-color);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .tagline-img {
    width: 100%;
  }

  .tagline-content {
    padding: 0;
    width: 100%;
  }

  .tagline-content-details {
    font-size: 1.2rem;
  }
}

.sectors-main {
  text-align: left;
  margin: 4rem;
  display: flex;
  flex-direction: column;
}

.title-placer {
  height: 5px;
  width: 6rem;
  background: var(--secondary-color);
  margin: 1rem 0 3rem;
}

.sector-cards {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  height: 60vh;
}

.sector-card-main {
  position: relative;
  flex: 1 1;
  color: white;
  border-radius: 10px;
}

.sector-card-img {
  object-fit: cover;
  width: 100%;
  border-radius: inherit;
  height: 100%;
}

.sector-card-know-more {
  position: absolute;
  top: 50%;
  left: 35%;
  display: none;
}

.sector-cards :hover {
  cursor: pointer;
  background: linear-gradient(
    0deg,
    rgba(0, 153, 204, 0.6),
    rgba(0, 153, 204, 0.6)
  );
}

.sector-cards :hover .sector-card-img {
  cursor: pointer;
  opacity: 0.3;
}

.sector-cards :hover .sector-card-know-more {
  display: block;
  cursor: pointer;
}

.sector-card-detail {
  position: absolute;
  left: 0px;
  bottom: 0px;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  width: 100%;
  background-color: var(--secondary-color);
  border-radius: inherit;
}

.sector-card-id {
  margin: 0;
  padding: 8px 12px;
}

.sector-card-title {
  font-weight: 300;
  font-size: 20px;
  margin: 0;
}

@media screen and (max-width: 500px) {
  .sectors-main {
    margin: 0;
    padding: 2rem 1.5rem;
  }
  .sector-cards {
    flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .sector-card-main {
    height: 30vh;
  }
  .sector-card-img {
    height: 30vh;
  }
}

.pol__left {
  background-color: #0a152b;
  color: white;
  border-bottom: 6px solid #0b793a;
  width: 98%;
  position: fixed;
  text-align: left;
  top: 120px;
  padding: 5px;
  padding-left: 25px;
  margin-bottom: 10px;
  margin-left: 1%;
  margin-right: 1%;
}
.pol__left > h1 {
  margin-left: 1%;
  font-weight: 600;
  font-size: 33px;
}

.pol__body {
  max-width: 100%;
  text-align: justify;
  padding: 33px 3.7% 2% 3.7%;
}

@media (max-width: 706px) {
  .pol__body {
    text-align: left;
  }
}

.pol__table {
  text-align: left;
}

.pol__head {
  font-weight: 900;
}

/* .pol__para {
  margin: 2% auto;
} */

.pol__afterlist {
  margin-top: 2%;
}

.app__button {
  background-color: #0b793a;
  border: 0px;
  color: white;
  border-radius: 0px;
  box-shadow: none;
  cursor: pointer;
  flex: 0 0 auto;
  padding: 5px 10px;
  margin: 15px;
}

#rcc-confirm-button {
  background-color: #0b793a !important;
  color: white !important;
}

@media (max-width: 756px) {
  .cookie__content {
    display: block !important;
  }
  .app__button {
    margin: 15px 0 0 0 !important;
  }
}

@media (max-width: 756px) {
  .CookieConsent {
    display: block !important;
  }

  #rcc-confirm-button {
    margin-top: 0px !important;
  }
}

.about-us-main{
    background: linear-gradient(180deg, rgba(10, 21, 43, 0) 0%, #0A152B 100%), url(/static/media/about.ec2ccb0d.png) no-repeat;
    background-size:cover;
    background-position: center;
    padding: 17% 20% 13%;
    color: white;
    display: flex;
    flex-direction: column;
    grid-gap:1rem;
    gap:1rem;
}

.about-us-title{
    font-weight: 700;
    font-size: 3rem;
}

.about-us-details{
    font-size: 1.3rem;
}

#footer-main-id{
    background: var(--first-color);
}

.about-us-links{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 2rem 1rem;
}

.about-us-links :hover{
    cursor: pointer;
}


.about-us-subscribe{
    width: 20%;
    padding: 2rem 1rem;
}

.about-us-input{
    display: flex;
    flex-direction: column;
}

.subscribe-label{
    text-align: left;
    font-size: 1rem;
}

.about-us-more-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
    padding: 0 7%;
    font-size: 0.8rem;
}

.about-us-more{
    display: flex;
    grid-gap:2rem;
    gap:2rem;
    color:#7B7E86;
}

.footer-location-icon-img{
    object-fit: cover;
    width: 20px;
}

.subscribe-btn{
    background: var(--secondary-color);
    border:var(--secondary-color);
}

@media screen and (max-width: 772px) {
    .about-us-main{
        padding: 2rem 1.5rem;
    }

    .about-us-title{
        font-size: 2rem;
    }

    .about-us-subscribe{
        padding: 1rem 0;
        width: 50%;
    }

    .about-us-links{
        padding: 1rem 0;
    }

    .about-us-more-div{
        flex-direction: column;
    }

    .about-us-details{
        font-size: 1.1rem;
    }

}
.agriculture-top-div{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 48.61%, rgba(0, 0, 0, 0.4) 100%),url(/static/media/agriculture.97068a59.jpg) no-repeat;
  height: 90vh;
  background-size: cover ;
  position: relative;
  background-position: center;
  color: white;
}



.agriculture__left {
  background-color: #0a152b;
  color: white;
  border-bottom: 6px solid #1597e5;
  width: 98%;
  position: fixed;
  text-align: justify;
  top: 120px;
  padding: 6px 5px 5px 25px;
  margin-bottom: 10px;
  margin-left: 1%;
  margin-right: 1%;
}

.agriculture__left > h1 {
  margin-left: 1%;
  font-weight: 600;
  font-size: 33px;
}

.agriculture__right {
  max-width: 100%;
  padding-right: 3.7%;
  padding-left: 3.7%;
  text-align: justify;
  margin: 75px auto 30px;
}

.agriculture__par > p {
  font-size: 16px;
  font-weight: 600;
}

.agriculture__box {
  background-color: #c3d6b8;
  padding: 22px 35px 10px 10px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.agriculture__list {
  background-color: #c3d6b8;
  padding: 22px 35px 10px 10px;
  font-size: 16px;
  font-weight: 600;
}

.agriculture__img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 991px) {
  .agriculture__img {
    margin-top: 3%;
    margin-bottom: 2%;
  }
}

.agriculture__bottom {
  margin-top: 1%;
  /* width: 75%; */
  /* margin: 50px auto; */
  text-align: justify;
}

@media (max-width: 772px) {
  .agriculture__right {
    text-align: left;
  }

  .agriculture__bottom {
    text-align: left;
  }
}

.agriculture__bottom > p {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: -0.5%;
}

.energy-top-div{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 48.61%, rgba(0, 0, 0, 0.4) 100%),url(/static/media/i-energy.6a18cf68.jpg) no-repeat;
  height: 90vh;
  background-size: cover ;
  position: relative;
  background-position: center;
  color: white;
}

.energy__left {
  background-color: #0a152b;
  color: white;
  border-bottom: 6px solid #1597e5;
  width: 98%;
  position: fixed;
  text-align: justify;
  top: 120px;
  padding: 6px 5px 5px 25px;
  margin-bottom: 10px;
  margin-left: 1%;
  margin-right: 1%;
}

.energy__left > h1 {
  margin-left: 1%;
  font-weight: 600;
  font-size: 33px;
}

.energy__right {
  max-width: 100%;
  padding-right: 3.7%;
  padding-left: 3.7%;
  text-align: justify;
  margin: 75px auto 30px;
}

.energy__par > p {
  font-size: 16px;
  font-weight: 600;
}

.energy__box {
  background-color: #c3d6b8;
  padding: 22px 35px 10px 10px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.energy__list {
  background-color: #c3d6b8;
  padding: 22px 35px 10px 10px;
  font-size: 16px;
  font-weight: 600;
}

.energy__img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 992px) {
  .energy__img {
    margin-top: 3%;
    margin-bottom: 1.7%;
  }
}

.energy__bottom {
  /* width: 75%; */
  /* margin: 50px auto; */
  margin-top: 1%;
  text-align: justify;
}

@media (max-width: 772px) {
  .energy__right {
    text-align: left;
  }

  .energy__bottom {
    text-align: left;
  }
}

.energy__bottom > p {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: -0.5%;
}

.estate-top-div{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 48.61%, rgba(0, 0, 0, 0.4) 100%),url(/static/media/i-estate.dd04d6b3.jpg) no-repeat;
  height: 90vh;
  background-size: cover ;
  position: relative;
  background-position: center;
  color: white;
}


.estate__left {
  background-color: #0a152b;
  color: white;
  border-bottom: 6px solid #1597e5;
  width: 98%;
  position: fixed;
  text-align: justify;
  top: 120px;
  padding: 6px 5px 5px 25px;
  margin-bottom: 10px;
  margin-left: 1%;
  margin-right: 1%;
}

.estate__left > h1 {
  margin-left: 1%;
  font-weight: 600;
  font-size: 33px;
}

.estate__right {
  max-width: 100%;
  padding-right: 3.7%;
  padding-left: 3.7%;
  text-align: justify;
  margin: 75px auto 30px;
}

.estate__par > p {
  font-size: 16px;
  font-weight: 600;
}

.estate__box {
  background-color: #c3d6b8;
  /* width: 95%; */
  padding: 22px 35px 10px 10px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.estate__list {
  background-color: #c3d6b8;
  /* width: 95%; */
  padding: 22px 35px 10px 10px;
  font-size: 16px;
  font-weight: 600;
}

.estate__img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 992px) {
  .estate__img {
    margin-top: 3%;
    margin-bottom: 1.7%;
  }
}

.estate__bottom {
  margin-top: 1%;
  /* width: 75%; */
  /* margin: 50px auto; */
  text-align: justify;
}

@media (max-width: 772px) {
  .estate__right {
    text-align: left;
  }

  .estate__bottom > p {
    text-align: left !important;
  }
}

.estate__bottom > p {
  text-align: justify;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: -0.5%;
}

.infra-top-div{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 48.61%, rgba(0, 0, 0, 0.4) 100%),url(/static/media/i-infrastructure.a6e90a62.jpg) no-repeat;
  height: 90vh;
  background-size: cover ;
  position: relative;
  background-position: center;
  color: white;
}

.inf__left {
  background-color: #0a152b;
  color: white;
  border-bottom: 6px solid #1597e5;
  width: 98%;
  position: fixed;
  text-align: justify;
  top: 120px;
  padding: 6px 5px 5px 25px;
  margin-bottom: 10px;
  margin-left: 1%;
  margin-right: 1%;
}

.inf__left > h1 {
  margin-left: 1%;
  font-weight: 600;
  font-size: 33px;
}

.inf__page {
  max-width: 100%;
  padding-right: 3.7%;
  padding-left: 3.7%;
  text-align: justify;
  margin: 75px auto 30px;
}

.inf__para {
  font-size: 16px;
  font-weight: 600;
}

.inf__box {
  background-color: #c3d6b8;
  padding: 22px 35px 10px 10px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1.3%;
}

.inf__list {
  background-color: #c3d6b8;
  padding: 22px 35px 10px 10px;
  font-size: 16px;
  font-weight: 600;
}

.inf__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.inf__bottom {
  margin-top: 1%;
  text-align: justify;
}

@media (max-width: 992px) {
  .inf__img {
    margin-top: 3%;
    margin-bottom: 1.7%;
  }

  .inf__bottom {
    margin-top: 5%;
  }
}

@media (max-width: 772px) {
  .inf__page {
    text-align: left;
  }

  .inf__bottom {
    text-align: left;
  }
}

.inf__bottom > p {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: -0.5%;
}

