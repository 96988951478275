.energy-top-div{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 48.61%, rgba(0, 0, 0, 0.4) 100%),url('../../assets/images/i-energy.jpg') no-repeat;
  height: 90vh;
  background-size: cover ;
  position: relative;
  background-position: center;
  color: white;
}

.energy__left {
  background-color: #0a152b;
  color: white;
  border-bottom: 6px solid #1597e5;
  width: 98%;
  position: fixed;
  text-align: justify;
  top: 120px;
  padding: 6px 5px 5px 25px;
  margin-bottom: 10px;
  margin-left: 1%;
  margin-right: 1%;
}

.energy__left > h1 {
  margin-left: 1%;
  font-weight: 600;
  font-size: 33px;
}

.energy__right {
  max-width: 100%;
  padding-right: 3.7%;
  padding-left: 3.7%;
  text-align: justify;
  margin: 75px auto 30px;
}

.energy__par > p {
  font-size: 16px;
  font-weight: 600;
}

.energy__box {
  background-color: #c3d6b8;
  padding: 22px 35px 10px 10px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.energy__list {
  background-color: #c3d6b8;
  padding: 22px 35px 10px 10px;
  font-size: 16px;
  font-weight: 600;
}

.energy__img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 992px) {
  .energy__img {
    margin-top: 3%;
    margin-bottom: 1.7%;
  }
}

.energy__bottom {
  /* width: 75%; */
  /* margin: 50px auto; */
  margin-top: 1%;
  text-align: justify;
}

@media (max-width: 772px) {
  .energy__right {
    text-align: left;
  }

  .energy__bottom {
    text-align: left;
  }
}

.energy__bottom > p {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: -0.5%;
}
