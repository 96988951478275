.footer__main {
  background-color: #666;
  max-width: 100%;
  /* align-items: center;
  margin-left: auto;
  margin-right: auto; */
}

.footer__sub {
  color: white;
  padding: 2% 6% 1% 6%;
}

.footer__add {
  display: flex;
  margin: 1%;
}

.footer__icon {
  margin-right: 2%;
}

.footer__first {
  text-align: left;
  padding: 1%;
}

.footer__pol {
  color: #666;
  border-color: #ebebeb;
  background-color: #ebebeb;
  font-weight: bolder;
  border-radius: 5%;
}

.footer__pol:hover {
  color: #666;
  border-color: #ebebeb;
  background-color: #ebebeb;
}
.footer__pol:focus {
  color: #666;
  border-color: #ebebeb;
  background-color: #ebebeb;
}

.footer__copy {
  margin: 4%;
}

.footer__image {
  width: 250px;
}

.footer__first > img {
  margin-bottom: 10px;
}

.footer__second > p {
  font-weight: 500;
  font-size: 16px;
}

.footer__second {
  text-align: justify;
  padding: 1%;
}

@media (max-width: 772px) {
  .footer__second {
    text-align: left;
  }

  .footer__first{
    text-align: center;
  }

  .footer__add {
    margin-left: 2.5rem;
    text-align: left;
  }
}

.footer__second > h3 {
  font-weight: 600;
  font-size: 50px;
}
