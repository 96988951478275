.about-us-main{
    background: linear-gradient(180deg, rgba(10, 21, 43, 0) 0%, #0A152B 100%), url('../../assets/images/about.png') no-repeat;
    background-size:cover;
    background-position: center;
    padding: 17% 20% 13%;
    color: white;
    display: flex;
    flex-direction: column;
    gap:1rem;
}

.about-us-title{
    font-weight: 700;
    font-size: 3rem;
}

.about-us-details{
    font-size: 1.3rem;
}

#footer-main-id{
    background: var(--first-color);
}

.about-us-links{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 2rem 1rem;
}

.about-us-links :hover{
    cursor: pointer;
}


.about-us-subscribe{
    width: 20%;
    padding: 2rem 1rem;
}

.about-us-input{
    display: flex;
    flex-direction: column;
}

.subscribe-label{
    text-align: left;
    font-size: 1rem;
}

.about-us-more-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
    padding: 0 7%;
    font-size: 0.8rem;
}

.about-us-more{
    display: flex;
    gap:2rem;
    color:#7B7E86;
}

.footer-location-icon-img{
    object-fit: cover;
    width: 20px;
}

.subscribe-btn{
    background: var(--secondary-color);
    border:var(--secondary-color);
}

@media screen and (max-width: 772px) {
    .about-us-main{
        padding: 2rem 1.5rem;
    }

    .about-us-title{
        font-size: 2rem;
    }

    .about-us-subscribe{
        padding: 1rem 0;
        width: 50%;
    }

    .about-us-links{
        padding: 1rem 0;
    }

    .about-us-more-div{
        flex-direction: column;
    }

    .about-us-details{
        font-size: 1.1rem;
    }

}