.navbar-main{
    display: flex ;
    justify-content: space-between !important;
    color: white;
    align-items: center;
    padding: 1.5rem 5rem 8px;
    background: var(--first-color);
    z-index: 150;
    position: fixed;
    width: 100%;
}

.eram-logo-img{
    object-fit: contain;
    width: 200px;
}

.navbar-tabs{
    display: flex;
    flex-direction: row;
    gap:2rem;
}

.navbar-main :hover{
    cursor: pointer;
}

.vertical-divider{
    width: 1px;
    height: 100%;
    background: white;
}

.vertical-divider :hover{
    pointer-events: none !important;
}

#collasible-nav-dropdown{
    color:white;
    padding: 0;
}

@media screen and (max-width: 772px) {
    .mobile-navbar-main {
      padding: 0.5rem 1rem 0 1rem;
      display: flex ;
      justify-content: space-between;
      align-items: center;
      background: var(--first-color);
      z-index: 150;
      position: fixed;
      width: 100%;
    }

    .menuItem-div {
        display: flex;
        flex-direction: column;
      }
    
      .drawer-container-main {
        position: fixed;
        z-index: 150;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        /* overflow-y: auto; */
        background-color: #fff;
        /* background-color: rgba(0, 0, 0, 0.7); */
        -webkit-animation-name: drawerFadeIn;
        -webkit-animation-duration: 0.4s;
        animation-name: drawerFadeIn;
        animation-duration: 0.4s;
        color: #fff;
      }
    
      .drawer-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        /* background-color: #fefefe; */
        width: 100%;
        height: 100%;
        -webkit-animation-name: contentSlideIn;
        -webkit-animation-duration: 0.4s;
        animation-name: contentSlideIn;
        animation-duration: 0.4s;
      }
    
      .menuItem-div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-radius: 10px;
        background-color: var(--first-color);
        margin: 2.5rem 0;
        padding: 1rem 2rem 3rem 2rem;
      }
      .menuContent-item {
        width: 100%;
        color: white;
        padding: 15px 5px 10px 5px;
        border-bottom: 1px solid #76939f;
        text-decoration: none;
      }

      #collasible-nav-dropdown{
        text-align: center;
      }

    .services-dropdown{
        display: flex;
        justify-content: center;
        margin: 16px 38%;    
    }
}