.pol__left {
  background-color: #0a152b;
  color: white;
  border-bottom: 6px solid #0b793a;
  width: 98%;
  position: fixed;
  text-align: left;
  top: 120px;
  padding: 5px;
  padding-left: 25px;
  margin-bottom: 10px;
  margin-left: 1%;
  margin-right: 1%;
}
.pol__left > h1 {
  margin-left: 1%;
  font-weight: 600;
  font-size: 33px;
}

.pol__body {
  max-width: 100%;
  text-align: justify;
  padding: 33px 3.7% 2% 3.7%;
}

@media (max-width: 706px) {
  .pol__body {
    text-align: left;
  }
}

.pol__table {
  text-align: left;
}

.pol__head {
  font-weight: 900;
}

/* .pol__para {
  margin: 2% auto;
} */

.pol__afterlist {
  margin-top: 2%;
}
