.contact-top-div{
  background-image: url('../../assets/images/contact-bg.png');
  background-size: contain;
  background-position: center;
  padding-bottom: 5rem;
}

.contact-form-div-main{
  padding: 4rem 12%;
  background-image: url('../../assets/images/contact-bg.png');
  background-size: contain;
  background-position: center;
  padding-bottom: 5rem;
}

.contact-form-card{
  padding: 4rem;
  background: white;
  box-shadow: 2px 10px 28px rgba(75, 0, 129, 0.12);
  border-radius: 10px;
}

.contact-us-label{
  letter-spacing: 0.01em;
  color: #4A99D3;
}

.contact-us-subtitle{
  font-size: 18px;
  color: #0F001A;
}

.contact-us-content{
  display: flex;
  padding: 2rem;
  justify-content: space-between;
}

.contact-form-main{
  width: 40%;
  display: flex;
  flex-direction: column;
  gap:1rem;
  text-align: left;
}

.contact-form-div{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contact-us-address{
  display: flex;
  flex-direction: column;
  width: 50%;
}

@media screen and (max-width:772px) {
  .contact-form-card{
    padding: 1rem 1.5rem;
  }

  .contact-form-div-main{
    padding: 3rem 1.5rem;
  }

  .contact-us-content{
    flex-direction: column;
    padding: 2rem 0 ;
    gap:2rem;
  }

  .contact-form-main{
    width: 100%;
  }

  .contact-us-address{
    width: 100%;
  }




}











.contact__top {
  background-color: #0a152b;
  color: white;
  border-bottom: 6px solid #1597e5;
  width: 98%;
  position: fixed;
  text-align: justify;
  top: 120px;
  padding: 5px;
  padding-left: 25px;
  margin-bottom: 10px;
  margin-left: 1%;
  margin-right: 1%;
}

.contact__top > h1 {
  margin-left: 1%;
  font-weight: 600;
  font-size: 33px;
}

.contact__map {
  padding-left: 3.7%;
  padding-right: 3.7%;
  padding-top: 30px;
  margin-top: 2%;
}

@media (max-width: 1281px) {
  .contact__map {
    padding-top: 48px;
  }
}

@media (max-width: 771px) {
  .contact__map {
    padding-top: 53px;
  }
}

@media (max-width: 513px) {
  .contact__map {
    padding-top: 60px;
  }
}

.contact__bottom {
  max-width: 100%;
  margin-top: 20px;
  padding-left: 4.7%;
  padding-right: 3.7%;
  margin-bottom: 2%;
  /* margin-bottom: 20px; */
}

.contact__bottom1 {
  /* margin-left: 1%;
  margin-right: 1%; */
  border: 15px solid #3d56b2;
  padding-left: 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  color: #3d56b2;
  text-align: left;
  height: 360px;
}

.contact__bottom1 > hr {
  border-top: 3px solid black;
  width: 10%;
}

.contact__bottom1 > p {
  font-weight: bold;
  font-size: 16px;
}

.contact__bottom2 {
  text-align: left;
}

.contact__input {
  display: block;
  margin-top: 10px;
  margin-bottom: 15px;
  width: 100%;
  padding-right: 0;
  padding: 23px;
}

@media (max-width: 768px) {
  .contact__input {
    margin: 4% auto;
    width: 100%;
  }
}

.contact__button {
  color: #3d56b2;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 35;
  height: 13%;
  font-weight: 900;
}
